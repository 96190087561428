import {getRestApi} from './ApiAxios'

export const ObtenerInformacionPaciente = async (id, datosEmpresa) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/ObtenerInformacionPaciente?datosEmpresa=' +
      datosEmpresa +
      '&usuarioId=' +
      id
  )
}

export const FachadaObtenerAtencionesAbiertas = async (
  atencionesDeHoy,
  sedeId,
  esAgendaMedico
) => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaObtenerAtencionesAbiertas', {
    params: {
      diaActual: atencionesDeHoy,
      sedeId: sedeId,
      esAgendaMedico: esAgendaMedico,
    },
  })
}

export const ObtenerHistoriaClinica = async (idUsuario) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/FachadaObtenerHistoriaClinicaPorPaciente?usuarioId=' +
      idUsuario
  )
}

export const ObtenerPacientes = async (numeroDocumento, nombre, apellidos) => {
  const apiReq = await getRestApi()
  let url = 'fachada-function/ObtenerPacientes?'
  if (numeroDocumento) {
    url = url + 'numeroDocumento=' + numeroDocumento + '&'
  }
  if (nombre) {
    url = url + 'nombre=' + nombre + '&'
  }
  if (apellidos) {
    url = url + 'apellidos=' + apellidos + '&'
  }
  return await apiReq.get(url)
}

export const FachadaObtenerMedicos = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaObtenerMedicos')
}

export const FachadaGenerarCertificadoExamenPreIngreso = async (Id) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/FachadaGenerarCertificadoExamenPreIngreso?servicioAtencionId=' +
      Id
  )
}

export const registrarPaciente = async (registro) => {
  const apiReq = await getRestApi()
  return await apiReq.put('fachada-function/CrearAtencion', registro)
}

export const FachadaAsignarTurno = async (datosPaciente) => {
  const apiReq = await getRestApi()
  return await apiReq.put('fachada-function/FachadaAsignarTurno', datosPaciente)
}

export const FachadaActualizarUsuario = async (datosParaActualizar) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaActualizarUsuario',
    datosParaActualizar
  )
}

export const FachadaGuardadoParcialExamenPreIngreso = async (
  examenPreingreso
) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaGuardadoParcialExamenPreIngreso',
    examenPreingreso
  )
}
export const FachadaCerrarHistoriaExamenPreIngreso = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaCerrarHistoriaExamenPreIngreso',
    payload
  )
}

export const FachadaCrearResultadoAliado = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'fachada-function/FachadaCrearResultadoAliado',
    payload
  )
}

export const FachadaActualizarResultadoAliado = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaActualizarResultadoAliado',
    payload
  )
}

export const CambiarALlamandoServicioAtencion = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/CambiarALlamandoServicioAtencion?servicioAtencionId=' +
      servicioAtencionId
  )
}

export const CambiarEnProcesoServicioAtencion = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/CambiarEnProcesoServicioAtencion?servicioAtencionId=' +
      servicioAtencionId
  )
}

export const FachadaCambiarAPendienteServicioAtencion = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaCambiarAPendienteServicioAtencion?servicioAtencionId=' +
      servicioAtencionId
  )
}

export const imprimirStickerLaboratorio = async (
  idAtencion,
  idsServiciosAtencion,
  idsLaboratorios
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/ImprimirStickerLaboratorio?atencionId=' +
      idAtencion +
      '&serviciosAtencionIds=' +
      idsServiciosAtencion +
      '&laboratoriosIds=' +
      idsLaboratorios,
    {
      responseType: 'blob',
    }
  )
}

export const FachadaObtenerLaboratoriosPorAtencionId = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/FachadaObtenerLaboratoriosPorAtencionId?atencionId=' +
      atencionId
  )
}

export const obtenerFacturasRechazadas = async (sedeId) => {
  const apiReq = await getRestApi()
  let {data: facturas, status} = await apiReq.get(
    'fachada-function/FachadaObtenerFacturasRechazadas',
    {params: {sedeId}}
  )

  return status === 200 ? facturas : []
}

export const obtenerErroresFacturaPorId = async (facturaId) => {
  const apiReq = await getRestApi()
  let {data: facturaConErrores, status} = await apiReq.get(
    'fachada-function/FachadaObtenerErroresFacturaPorId',
    {params: {facturaId}}
  )

  return status === 200 ? facturaConErrores : {}
}

export const corregirFactura = async (datosFactura) => {
  const apiReq = await getRestApi()
  apiReq.patch('gato-api-function/FachadaCorregirFactura', datosFactura)
}

export const FachadaGuardarFormulaOptometrica = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaGuardarFormulaOptometrica',
    datos
  )
}

export const FachadaGenerarPDFIncapacidadMedica = async (
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'post',
    url: 'fachada-function/FachadaGenerarPDFIncapacidadMedica',
    data: {servicioAtencionId},
  })
}

export const FachadaGuardarFormulaMedica = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaGuardarFormulaMedica',
    datos
  )
}

export const FachadaGuardarFormatoInterconsulta = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaGuardarFormatoInterconsulta',
    datos
  )
}

export const GenerarFormatoFormulaMedica = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'fachada-function/GenerarFormatoFormulaMedica',
    params: {servicioAtencionId},
  })
}

export const GenerarFormatoInterconsulta = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'fachada-function/GenerarFormatoInterconsulta',
    params: {servicioAtencionId},
  })
}

export const GenerarFormatoFormulaOptometrica = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'fachada-function/GenerarFormatoFormulaOptometrica',
    params: {servicioAtencionId},
  })
}

export const FachadaObtenerSolicitudesModificarAtencion = async (
  sedeId,
  numeroDocumento,
  nombre,
  apellidos
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'fachada-function/FachadaObtenerSolicitudesModificarAtencion',
    data: {sedeId, numeroDocumento, nombre, apellidos},
  })
}

export const FachadaGuardarParcialmente = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaGuardarParcialmente',
    datos
  )
}

export const FachadaCerrarHistoria = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.patch('fachada-function/FachadaCerrarHistoria', datos)
}

export const FachadaGenerarCertificadoExamen = async (
  servicioAtencionId,
  examenFinalizado
) => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaGenerarCertificadoExamen', {
    params: {servicioAtencionId, examenFinalizado},
  })
}

export const FachadaGenerarDocumentoExamen = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaGenerarDocumentoExamen', {
    params: {servicioAtencionId},
  })
}

export const FachadaObtenerFacturas = async (fechaInicial, fechaFinal) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    params: {fechaInicial, fechaFinal},
    url: 'fachada-function/FachadaObtenerFacturas',
  })
}

export const FachadaObtenerConteoDeEstadosFactura = async (
  fechaInicial,
  fechaFinal
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    params: {fechaInicial, fechaFinal},
    url: 'fachada-function/FachadaObtenerConteoDeEstadosFactura',
  })
}

export const FachadaValidarServiciosLaboratorioPorAtencionId = async (
  atencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'patch',
    params: {atencionId},
    url: 'fachada-function/FachadaValidarServiciosLaboratorioPorAtencionId',
  })
}

export const FachadaCambiarALlamandoLaboratorios = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'patch',
    params: {atencionId},
    url: 'fachada-function/FachadaCambiarALlamandoLaboratorios',
  })
}

export const FachadaReporteAtenciones = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaReporteAtenciones')
}

export const FachadaObtenerEjecucionesSede = async (sedeId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaObtenerEjecucionesSede', {
    params: {sedeId},
  })
}
export const FachadaObtenerEjecucionesEmpresas = async (
  sedeId,
  fechaInicial,
  fechaFinal
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/FachadaObtenerEjecucionesEmpresas',
    {
      params: {
        sedeId: sedeId,
        fechaDesde: fechaInicial,
        fechaHasta: fechaFinal,
      },
    }
  )
}
export const FachadaCambiarAAtendiendoLaboratorios = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'patch',
    params: {atencionId},
    url: 'fachada-function/FachadaCambiarAAtendiendoLaboratorios',
  })
}

export const FachadaObtenerServiciosPorAtencionId = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/FachadaObtenerServiciosPorAtencionId',
    {
      params: {atencionId},
    }
  )
}
export const FachadaCambiarEstadoServicioAtencion = async (
  servicioAtencionId,
  enviarMensajeSignal
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'patch',
    params: {servicioAtencionId, enviarMensajeSignal},
    url: 'fachada-function/FachadaCambiarEstadoServicioAtencion',
  })
}

export const FachadaObtenerAtencionesUsuarioFinalizadas = async ({
  numeroDocumento = '',
  nombre = '',
  apellidos = '',
  sedeId,
}) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerAtencionesUsuarioFinalizadas',
    params: {numeroDocumento, nombre, apellidos, sedeId},
  })
}
export const FachadaObtenerLaboratoriosPendientesPorCerrarPorSedeId = async (
  laboratoriosDeHoy,
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'fachada-function/FachadaObtenerLaboratoriosPendientesPorCerrarPorSedeId?fechaActual=' +
      laboratoriosDeHoy +
      '&sedeId=' +
      sedeId
  )
}

export const FachadaObtenerAtencionesPorDatosBasicosUsuario = async ({
  numeroDocumento = '',
  nombre = '',
  apellidos = '',
}) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerAtencionesPorDatosBasicosUsuario',
    params: {numeroDocumento, nombre, apellidos},
  })
}

export const FachadaObtenerDetalleAtencion = async (
  atencionId,
  serviciosFinalizados = true
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerDetalleAtencion',
    params: {atencionId, serviciosFinalizados},
  })
}

export const FachadaObtenerSolicitudPorId = async (solicitudId) => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaObtenerSolicitudPorId', {
    params: {
      solicitudId,
    },
  })
}

export const ObtenerPlantillaPorServicioIdConDatos = async (
  servicioId,
  servicioAtencionId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'GET',
    url: 'fachada-function/ObtenerPlantillaPorServicioIdConDatos',
    params: {servicioId, servicioAtencionId},
  })
}

export const FachadaObtenerAtencionesUsuario = async (datos) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'fachada-function/FachadaObtenerAtencionesUsuario',
    datos
  )
}

export const FachadaGenerarReciboCajaAtencion = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'fachada-function/FachadaGenerarReciboCajaAtencion',
    atencionId
  )
}

export const FachadaObtenerAtencion = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerAtencion',
    params: {atencionId},
  })
}

export const FachadaObtenerDatosCreacionAtencion = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerDatosCreacionAtencion',
    params: {atencionId},
  })
}

export const FachadaAgregarMotivo = async (datosMotivo) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaAgregarMotivo',
    datosMotivo
  )
}

export const FachadaModificarAtencion = async (datosAtencion) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaModificarAtencion',
    datosAtencion
  )
}

export const FachadaFinalizarModificacionAtencion = async (atencionId) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'fachada-function/FachadaFinalizarModificacionAtencion',
    atencionId
  )
}

export const RecargarSaldoCuenta = async (recarga) => {
  const apiReq = await getRestApi()
  return await apiReq.put('fachada-function/FachadaRecargarSaldo', recarga)
}

export const ValidarEstadoTransaccion = async (
  empresaId,
  transaccionId,
  ip
) => {
  const apiReq = await getRestApi()
  return await apiReq.get('fachada-function/FachadaValidarEstadoTransaccion', {
    params: {
      empresaId,
      transaccionId,
      ip,
    },
  })
}

export const CrearMovimientoEmpresa = async (movimiento) => {
  const apiReq = await getRestApi()
  return await apiReq.put(
    'fachada-function/FachadaCrearMovimientoEmpresa',
    movimiento
  )
}

export const FachadaObtenerAtencionesPorDiaSedeId = async (
  fecha = '',
  sedeId
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerAtencionesPorDiaSedeId',
    params: {fecha, sedeId},
  })
}

export const FachadaObtenerResultadoAliado = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerResultadoAliadoPorId',
    params: {id},
  })
}

export const FachadaObtenerCantidadAtencionesPorMesSedeId = async (
  sedeId,
  fecha = ''
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerCantidadAtencionesPorMesSedeId',
    params: {sedeId, fecha},
  })
}

export const FachadaGenerarReciboCajaRecarga = async (recibo) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'fachada-function/FachadaGenerarReciboCajaRecarga',
    recibo
  )
}

export const FachadaCrearEmpresa = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'put',
    url: 'fachada-function/FachadaCrearEmpresa',
    data: payload,
  })
}

export const FachadaObtenerTarifarios = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'post',
    url: 'fachada-function/FachadaObtenerTarifarios',
    data: payload,
  })
}

export const GenerarFormatoSolicitudValoracion = async (servicioAtencionId) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'POST',
    url: 'fachada-function/GenerarFormatoSolicitudValoracion',
    params: {servicioAtencionId},
  })
}

export const FachadaObtenerInformacionCierresPorSedeYFecha = async (
  sedeId,
  fecha,
  correoRecepcionista
) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/ObtenerInformacionCierresPorSedeYFecha',
    params: {sedeId, fecha, correoRecepcionista},
  })
}

export const FachadaObtenerUsuariosB2c = async () => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'get',
    url: 'fachada-function/FachadaObtenerUsuariosB2c',
  })
}
